var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c("div", { staticClass: "inline-block" }, [
    _c(
      "p",
      {
        staticClass: "flex items-center mb-5 text-lightGrey-2 cursor-pointer",
        on: {
          click: function ($event) {
            return _vm.$emit("click")
          },
        },
      },
      [
        _c(
          "svg",
          {
            attrs: {
              xmlns: "http://www.w3.org/2000/svg",
              width: "8",
              height: "14",
              viewBox: "0 0 8 14",
              fill: "none",
            },
          },
          [
            _c("path", {
              attrs: {
                d: "M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.0867 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z",
                fill: "#6B6873",
              },
            }),
          ]
        ),
        _c("span", { staticClass: "ml-3 text-sm" }, [_vm._v(_vm._s(_vm.text))]),
      ]
    ),
  ])
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }