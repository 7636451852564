<template>
  <div class="inline-block">
    <p
      class="flex items-center mb-5 text-lightGrey-2 cursor-pointer"
      @click="$emit('click')"
    >
      <svg
        xmlns="http://www.w3.org/2000/svg"
        width="8"
        height="14"
        viewBox="0 0 8 14"
        fill="none"
      >
        <path
          d="M7.08004 0.993291C6.75337 0.666624 6.22671 0.666624 5.90004 0.993291L0.360039 6.53329C0.100039 6.79329 0.100039 7.21329 0.360039 7.47329L5.90004 13.0133C6.22671 13.34 6.75337 13.34 7.08004 13.0133C7.40671 12.6866 7.40671 12.16 7.08004 11.8333L2.25337 6.99996L7.0867 2.16662C7.4067 1.84662 7.40671 1.31329 7.08004 0.993291Z"
          fill="#6B6873"
        /></svg
      ><span class="ml-3 text-sm">{{ text }}</span>
    </p>
  </div>
</template>
<script>
  export default {
    props: {
      text: {
        type: String,
        default: "Back",
        required: false,
      },
    },
  };
</script>
